<template>
  <div class="card shadow mb-7">
    <div class="card-body pt-6 pb-0">
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch text-center nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/sintesi-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Sintesi
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/affiliazioni-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Affiliazioni/Aggregazioni
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/tesseramenti-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Tesseramenti
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/pagamenti-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Pagamenti
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/movimenti-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Movimenti
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/sanzioni-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Sanzioni
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/tessere-speciali-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Tessere speciali
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/tessere-gold-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Tessere gold
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/saldo-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Saldi
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/area-gestionale/economato/compensazioni-economato"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Compensazioni
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Economato",
  components: {},
});
</script>
